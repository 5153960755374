import i18n from "@/plugins/i18n";
import { RouteNav } from "./classes";
import {
  IApiRouteNames,
  IPerMonthStatistics,
  IRoutesNames,
} from "./interfaces";

// The possible artist types.
// Used in SongInfo class.
const ARTISTTYPES = ["Interpret", "Composer", "Editor", "Texter"];

// The possible phone prefixes.
const PHONEPREFIXES = ["+43", "+49", "+41"];

// The regex pattern to validate passwords.
const PASSWORD_REGEX_PAT =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\!\#\$\%\&\'\(\)\*\+\,\-\.\:\;\<\=\>\?\@\[\]\^_\{\|\}\~\\\/]).{6,}$/;

// The regex pattern to validate celphones.
const CEL_REGEX_PAT = /^[0-9]*$/;

// The regex pattern for a valid kokoid.
const KokoId_REGEX_PAT = /^CD33(\/[0-9]{1,5})+(_Z)?$/;

// The regex pattern for a valid kokoid album.
const KokoId_Album_REGEX_PAT = /^CD33(\/[0-9]{1,5})$/;

const Similaritysearch_URL_PAT =
  /^https:\/\/(www\.)?((youtu\.be\/.*|youtube\.com\/watch\?.*)|soundcloud\.com.*)$/;

const Similaritysearch_Youtube_PAT = /^https:\/\/(www\.)?(youtu\.be|youtube\.com\/watch\?.*)$/;

// The selector of how many song results to show per page.
// Used mainly by pagination.
const SONG_RESULTS_PER_PAGE = [15, 25, 35];

// The selector of how many cart results to show per page.
// Used mainly by pagination.
const CART_RESULTS_PER_PAGE = [5, 10, 15];

// The key to identify played songs in localstorage.
const SONGS_PLAYED_LOCALSTORAGE_KEY = "musiclibraySongsPlayed";

// The cookie key for the users email.
const USER_SESSIONSTORAGE_KEY = "user";

// The cookie key to see if the user is a MV user.
const IS_MV_USER_SESSIONSTORAGE_KEY = "isMvUser";

// The session storage key that holds the latest search.
const SEARCH_FORM_SESSIONSTORAGE_KEY = "search";

// The routes for a dropdown.
const navMusikverlagDropDownDE = [
  new RouteNav("Home", "https://musikverlag.orf.at/"),
  new RouteNav(
    "Was wir tun",
    "https://musikverlag.orf.at/musikverlag/was-wir-tun/"
  ),
  new RouteNav(
    "Infos für Musikschaffende",
    "https://musikverlag.orf.at/musikverlag/infos-fuer-musikschaffende/"
  ),
  new RouteNav(
    "Infos für ORF-Gestalter & Filmschaffende",
    "https://musikverlag.orf.at/musikverlag/infos-fuer-orf-gestalter-filmschaffende/"
  ),
  new RouteNav(
    "Infos für Werbetreibende",
    "https://musikverlag.orf.at/musikverlag/infos-fuer-werbetreibende/"
  ),
  new RouteNav(
    "Unsere Komponistinnen/Komponisten",
    "https://musikverlag.orf.at/musikverlag/unsere-komponistinnenkomponisten/"
  ),
];

// The routes for a dropdown.
const navMusiclibraryDropDownDE = [
  new RouteNav("Musiksuche", "/song/index/"),
  new RouteNav(
    "Lizenzierung FAQ",
    "https://musikverlag.orf.at/musiclibrary/lizenzierung-faq/"
  ),
  new RouteNav(
    "Preisliste Werbliche Nutzung",
    "https://musikverlag.orf.at/musiclibrary/preisliste-werbliche-nutzung/"
  ),
];

// The routes for a dropdown.
const navSammelkorbDropDownDE = [
  new RouteNav("MusikSammlungen", "/song/index/popup"),
  // new RouteNav("Ähnlichkeitssuche", "/song/similarity-search"),
  new RouteNav("Aktueller Sammelkorb", "/shoppingcart/index"),
  new RouteNav("Meine Sammelkörbe", "/shoppingcart/management"),
];

// The routes for a dropdown.
const navLabelDropDownDE = [
  new RouteNav(
    "Unsere Produktion",
    "https://musikverlag.orf.at/label/unsere-produktionen/"
  ),
  new RouteNav("Musikvideos", "https://musikverlag.orf.at/label/musikvideos/"),
];

// The routes for a dropdown.
const navHelpDropDownDE = [
  new RouteNav("FAQ", "https://musikverlag.orf.at/hilfe/faq/"),
  new RouteNav(
    "Anleitung ORF-Musiclibrary",
    "https://musikverlag.orf.at/hilfe/anleitung-orf-musiclibrary/"
  ),
  new RouteNav("Kennwort Vergessen", "/identity/resetpassword"),
  new RouteNav("Kontakt", "https://musikverlag.orf.at/hilfe/kontakt/"),
  new RouteNav(
    "Über ORF-Musiclibrary",
    "https://musikverlag.orf.at/hilfe/ueber-orf-musiclibrary/"
  ),
];

// The routes for the admin dropdown.
const navAdminDropDownDe = [
  new RouteNav("Themensammlungen", "/admin/collections"),
  new RouteNav("Track Export", "/admin/export"),
  new RouteNav("Wartungsinformationen und Updates", "/admin/maintainance"),
  new RouteNav("Statistiken", "/admin/statistics"),
  new RouteNav("Benutzerverwaltung", "/admin/users"),
  new RouteNav("Dropdowns Bearbeiten", "/admin/dropdowns"),

];

// The route names for the app.
const routeNames: Readonly<IRoutesNames> = {
  home: "Home",
  cartIndex: "CartIndex",
  cartsManagement: "CartsManagement",
  cartManagement: "CartManagement",
  publicCart: "PublicCart",
  targetExport: "TargetExport",
  luceneSearch: "LuceneSearch",
  musicIndex: "musicIndex",
  login: "Login",
  logout: "Logout",
  register: "Register",
  resetPassword: "ResetPassword",
  resetPasswordRequest: "ResetPasswordReq",
  editAccount: "EditAccount",
  verifyAccount: "VerifyAccount",
  verifyEmail: "VerifyEmail",
  generalError: "GeneralError",
  internalError: "InternalError",
  notFoundError: "NotFound",
  notImplementedError: "NotImplemented",
  emailVerifyError: "EmailVerifyError",
  userVerifyError: "UserVerifyError",
  emailUpdateInfo: "InfoEmail",
  emailVerifyInfo: "EmailVerify",
  emailChangeInfo: "EmailChange",
  verifyUserInfo: "VerifyUser",
  adminView: "AdminView",
  adminExportView: "AdminExportView",
  adminMaintainanceView: "AdminMaintainanceView",
  adminStatisticsView: "AdminStatisticsView",
  adminUsersView: "AdminUsersView",
  adminDropdownView: "AdminDropdownView",
  homeWithOpenPopup: "HomeWithOpenPopup",
  similaritySearch: "SimilaritySearch",
};

// The names of the api controllers.
const apiRouteNames: Readonly<IApiRouteNames> = {
  prefix: "/api/",
  account: "account",
  identity: "identity",
  shoppingcart: "shoppingcart",
  song: "song",
  file: "file",
  news: "news",
  maintainance: "maintainance",
  statistics: "statistics",
};

// This is used to identitfy the cookie key for the news modal.
const NEWS_MODAL_IDENTIFIER_KEY = "news";

// This value is compared with the number in the news cookie.
// Update this if there is new functionality so the News Modal gets displayed again
const CURRENT_NEWS_VERSION = "1.2";

// This is used to identify the credential cookie.
const CREDENTIAL_COOKIE_IDENTIFIER_KEY = "user";

// This constant holds the dropdown categories.
const DROPDOWN_ITEMS_CATEGORY_DE = [
  "not found",
  i18n.t("searchFormTitleMusicStyle").toString(),
  i18n.t("searchFormTitleInstrument").toString(),
  i18n.t("searchFormTitleEmotion").toString(),
  i18n.t("searchFormTitleOccasion").toString(),
];

const perMonthStatistics: Readonly<IPerMonthStatistics> = {
  views: 1,
  newUsers: 2,
  queries: 3,
  dropdowns: 4,
};

export {
  SONGS_PLAYED_LOCALSTORAGE_KEY,
  CART_RESULTS_PER_PAGE,
  SONG_RESULTS_PER_PAGE,
  CEL_REGEX_PAT,
  PASSWORD_REGEX_PAT,
  PHONEPREFIXES,
  ARTISTTYPES,
  navHelpDropDownDE,
  navLabelDropDownDE,
  navSammelkorbDropDownDE,
  navMusiclibraryDropDownDE,
  navMusikverlagDropDownDE,
  routeNames,
  USER_SESSIONSTORAGE_KEY,
  IS_MV_USER_SESSIONSTORAGE_KEY,
  apiRouteNames,
  SEARCH_FORM_SESSIONSTORAGE_KEY,
  NEWS_MODAL_IDENTIFIER_KEY,
  CURRENT_NEWS_VERSION,
  navAdminDropDownDe,
  CREDENTIAL_COOKIE_IDENTIFIER_KEY,
  DROPDOWN_ITEMS_CATEGORY_DE,
  KokoId_REGEX_PAT,
  KokoId_Album_REGEX_PAT,
  perMonthStatistics,
  Similaritysearch_URL_PAT,
  Similaritysearch_Youtube_PAT
};
