<template>
  <div class="app-body">
    <NavBar :key="$route.fullPath" />
    <router-view />
    <CustomFooter />
    <b-overlay
      v-if="isLoading"
      :show="isLoading"
      rounded="sm"
      variant="dark"
      opacity="0.5"
      class="overlay-classic"
    >
      <template #overlay>
        <div class="d-flex loader">
          <img
            src="./assets/loader.gif"
            alt="loading"
            style="width: 100px; height: 100px"
          >
        </div>
      </template>
    </b-overlay>
  </div>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import CustomFooter from "@/components/CustomFooter.vue";
import RequestFuncs from "./util/RequestFunc";

export default {
  // This is the entry point for the app.
  name: "App",
  metaInfo: {
    titleTemplate: "ORF-ENTERPRISE Musiclibrary",
    meta: [
      { charset: "utf-8" },
      { name: "robots", content: "index,follow" },
      { name: "google", content: "notranslate" },
      { name: "apple-mobile-web-app-capable", content: "no" },
      {
        name: "description",
        content:
          "ORF-Enterprise Musikverlag, Label und Musiclibrary - Auf unserer Website finden Sie alles über unsere Tonträgerproduktionen, sowie unsere Musiclibrary für Filmschaffende.",
      },
      { name: "author", content: "ORF-Enterprise Musikverlag" },
      {
        name: "keywords",
        content:
          "ORF, ORF-Enterprise, Musiclibrary, Musiksuche, Label, Tonträger, Produktionen,  online, music library, production music, soundtrack, stock music, verlag, record label, music publisher, mp3, film, tv, rise like a phoenix,",
      },
    ],
  },
  components: {
    NavBar,
    CustomFooter,
  },
  computed: {
    // Gets a boolean indicating wether the app loading.
    isLoading() {
      return this.$store.getters.getAppLoadingBool;
    },
  },
  async mounted() {
    // Uptick View counter for the website.
    await RequestFuncs.addWebsiteView();
  },
};
</script>
<style lang="scss">
NavBar {
  align-self: center;
}
</style>
