import Vue from "vue";
import VueI18n from "vue-i18n";

// Register i18n in the vue app.
Vue.use(VueI18n);

// The localized messages.
const messages = {
  de: {
    testMsg: "Test",
    navLogin: "Login",
    navLogout: "Logout",
    navRegister: "Registrieren",
    navAccount: "Benutzerkonto verwalten",
    navDropDownTitleMusikverlag: "Musikverlag",
    navDropDownTitleMusiclibrary: "Musiclibrary",
    navDropDownTitleShoppingcart: "Sammelkörbe",
    navDropDownTitleLabel: "Label",
    navDropDownTitleHelp: "Hilfe",
    navDropDownTitleAdmin: "Admin",
    searchFormTitleMusicStyle: "Musikstil",
    searchFormTitleEmotion: "Emotion/Stimmung",
    searchFormTitleOccasion: "Musik für...",
    searchFormTitleInstrument: "Instrument",
    searchFormTitleTimeM: "Min.",
    searchFormTitleTimeS: "Sek.",
    searchFormTitleSearch: "Volltextsuche",
    searchFormSimSearch: "Ähnlichkeitssuche mit URL (copy/paste von z.B. Youtube-Link) oder ArchivNr.",
    searchFormFileSearch: "Ähnlichkeitssuche mit Audio-Datei",
    similaritySearch: "Ähnlichkeitssuche",
    searchCart: "Sammelkorb suchen",
    musicCollection: "MUSIKSAMMLUNG",
    searchSongInCart: "Tracks im Sammelkorb suchen",
    searchFormResetButton: "Zurücksetzen",
    searchFormSubmitButton: "Suchen",
    searchFormCurrentCart: "Aktueller Sammelkorb",
    searchFormLabelMinDuration: "Dauer von",
    searchFormLabelMaxDuration: "Dauer bis",
    searchFormEmployeeSearchLabel: "Zur Such-Ansicht für MV-Mitarbeiter",
    searchFormShowMask: "Suche anzeigen",
    loginViewHeader: "Login",
    loginViewEmailLabel: "E-MAIL",
    loginViewPasswordLabel: "Kennwort",
    loginViewLoginButton: "Login",
    loginViewResetPasswordText: "Kennwort vergessen?",
    loginViewResetPasswordButton: "Jetzt zurücksetzen!",
    loginViewNotRegisteredText: "Noch nicht registriert?",
    loginViewNotRegisteredTextAnchor: "Jetzt registrieren!",
    resetPasswordViewHeader: "Kennwort zurücksetzen",
    resetPasswordViewInfo:
      "Um Ihr Kennwort zurückzusetzen, geben Sie hier Ihre Email-Adresse ein.",
    registerViewHeader: "Registrieren",
    registerViewRepeatEmail: "E-MAIL wiederholen",
    registerViewRepeatPassword: "Kennwort wiederholen",
    registerViewTitleLabel: "Titel",
    registerViewNameLabel: "Vorname",
    registerViewSurNameLabel: "Nachname",
    registerViewCompanyLabel: "Unternehmen",
    registerViewJobLabel: "Tätigkeit",
    registerViewPhoneLabel: "Telefonnummer",
    registerViewInfoText:
      "Hier können Sie sich als ORF-Produktionspartner, ORF-Gestalter oder Filmproduzent zur Nutzung der Musiclibrary registrieren. Als Nutzer der Musiclibrary können Sie Musiktitel speichern, downloaden, und gem. den {terms} nutzen. Die mit einem Stern gekennzeichneten Felder sind Pflichtfelder. Nach dem Senden der Daten wird Ihr Account von uns geprüft und schnellstmöglich freigeschalten. Sie erhalten eine Bestätigung an die angegebene E-Mail-Adresse. ",
    registerViewSubmitBtn: "Registrieren",
    termsOfUseAnchor: "Nutzungsbedingungen/AGB",
    findHere: "hier",
    registerViewCheckInfoText:
      "Ich habe die {terms} gelesen und akzeptiert und stimme der Speicherung und Verarbeitung meiner Daten für die Registrierung bzw. Nutzung der ORF-Enterprise Musiclibrary (z.B. für den Download von Audio-Dateien) zu. Informationen gemäß Art. 13 DSGVO finden Sie {here}.",
    navbarGreeting: "Hallo, ",
    tHead0: "Titel/ Komponist/ Dauer",
    tHead1: "Stichworte",
    archiveNumber: "ArchivNr.",
    songItemAkmData: "AKM-Daten",
    songItemComposer: "Komponist",
    songItemEditor: "Bearbeiter",
    songItemInterpret: "Interpret",
    songItemTexter: "Texter",
    songItemLabel: "Verlag / Label",
    songItemDuration: "Dauer",
    songItemExtraData: "Sonstige Daten",
    songItemArchiveNumber: "ArchivNr.",
    songItemAlbumTitle: "Albumtitel",
    songItemGenre: "Genre",
    songItemInstruments: "Instrumente",
    songItemKeywords: "Stichworte",
    songItemRecordingYear: "Aufnahmejahr",
    addToCart: "In Sammelkorb hinzufügen",
    deleteFromCart: "Aus Sammelkorb entfernen",
    mp3DownloadLabel: "Download MP3",
    wavDownloadLabel: "Download WAV",
    itemsPerPage: "Tracks pro Seite",
    createCartModalTitle: "Neuen Sammelkorb anlegen",
    createCartModalName: "Name",
    createCartModalDefinition: "Beschreibung",
    cancelButton: "Abbrechen",
    createButton: "Erstellen",
    createCartSuccessMSG: "Neuer Sammelkorb erfolgreich erstellt!",
    copyCartSuccessMSG: "Sammelkorb erfolgreich kopiert!",
    editCartSuccessMSG: "Sammelkorb erfolgreich gespeichert!",
    createThemecartModalTitle: "Themensammlung erstellen",
    createThemecartModalOnlyPngText:
      "Es können nur Dateien mit der Endung .png hochgeladen werden!",
    editThemecartModalTitle: "Themensammlung bearbeiten",
    createThemecartSuccessMSG: "Neue Themensammlung erfolgreich erstellt!",
    editThemecartSuccessMSG: "Themensammlung erfolgreich gespeichert!",
    // editThemecartModalCoverUploadConflict:
    //   "Eine Datei mit dem selben Namen befindet sich bereits auf dem Server!",
    // editThemecartModalCoverUploadSuccess: "Datei erfolgreich hochgeladen.",
    themecartModalCoverSelectorEmpty: "Cover wählen",
    noResults: "Keine Treffer",
    noResultsSearch: "Versuchen Sie das Suchwort unter \"\" zu setzten um genauere Ergebnisse zu erzielen",
    dataIsLoading: "Daten werden geladen",
    songSearchGreetings1: "Die ORF-Musiclibrary - ",
    allSongs: "Insgesamt",
    luceneSearchFormTitle: "Lucene Suchanleitung",
    luceneSearchMSG:
      'Dokumentation für die Lucene-Querysyntax finden Sie {here}. {end} Diese Suche ist unterschiedlich zu der Suche von normalen Benutzern, hier wird Ihr Suchtext direkt an Lucene (und unbearbeitet) an das Lucene-Framework weitergegeben. {end} Beispiel: Sie geben in das Suchfeld den Text "Winter" ein. Lucene wird nun in ALLEN Feldern (siehe Tabelle) nach exakten Treffern suchen (z.B. Lucene würde etwas finden wenn "Winter" in den Schlüsselwörtern eines Tracks vorkommt, allerdings nicht wenn "Winterland" vorkommt ). Wollen Sie ausschließlich nach "Winter" nur in den Schlüsselwörtern suchen, geben Sie "Keywords:Winter" in das Suchfeld ein. ',
    luceneSearchTableFieldname: "Lucene-Feldname",
    luceneSearchTableIndustryNumber: "Industrienummer",
    luceneSearchTableIndustryTrackName: "Name des Tracks",
    luceneSearchTableIndustryAlbum: "Album",
    luceneSearchTableIndustryGenre: "Genre",
    luceneSearchTableIndustryInstrument: "Klangkörper",
    luceneSearchTableIndustryKeywords: "Schlüsselwörter",
    luceneSearchTableIndustryArtists: "Künstler, Komponisten, Bearbeiter",
    copy: "Kopieren",
    edit: "Bearbeiten",
    delete: "Löschen",
    carts: "Sammelkörbe",
    tracks: "Tracks",
    similar: "Ähnliche",
    collections: "Themensammlungen",
    cartsManagement: "Sammelkorb anlegen",
    cartsManagementTitle: "Verwaltung",
    cartsManagementLoadCart: "Sammelkorb laden",
    cartsManagementCreated: "Erstellt am",
    cartsManagementTitleCount: "Anzahl Titel",
    cartsManagementTransferSongs: "Tracks kopieren",
    cartsManagementTransferSongsModalTitle: "Ziel Sammelkorb auswählen",
    cartsManagementTransferSongsSuccessMSG:
      "Sammelkörbe wurden erfolgreich angepasst",
    cartsManagementEmptyTitle: "KEINER VORHANDEN",
    save: "Speichern",
    mode: "Modi",
    export: "Exportieren",
    emptyCartMSG: "Diesem Sammelkorb wurden noch keine Tracks hinzugefügt.",
    emptyCartSearchMSG: "Keine Tracks gefunden!",
    editAccountTitle: "Userdaten verwalten",
    editAccountMsg: "Hier können Sie Ihre Daten ändern.",
    editAccountTabPersonalData: "Personendaten",
    editAccountTabEmail: "Email",
    editAccountSubmitBtn: "Ändern",
    errorEmailVerify: "Die Email Addressen müssen identisch sein!",
    errorPasswordVerify: "Die Kennwörter müssen identisch sein!",
    errorPasswordRegex:
      "Dieses Kennwort entspricht nicht unseren Richtlinien. Bitte stellen Sie sicher, dass das Kennwort mindestens 6 Zeichen lang ist. Mindestens ein Groß- und Kleinbuchstabe, eine Ziffer (0-9) als auch ein Sonderzeichen (.*[#$^+=!*()@%&) müssen in dem Kennwort vorhanden sein.",
    errorEmailEmpty: "Sie müssen eine gültige Email Addresse angeben!",
    errorMaxChar: "Das Feld darf nur 255 Charaktere enthalten!",
    errorMaxChar128: "Das Feld darf nur 128 Charaktere enthalten!",
    errorMaxChar512: "Das Feld darf nur 512 Charaktere enthalten!",
    errorMaxChar2048: "Das Feld darf nur 2048 Charaktere enthalten!",
    errorFieldEmpty: "Sie müssen dieses Feld ausfüllen!",
    errorCel: "Sie müssen eine valide Telefonnummer angeben!",
    errorTermsCheckbox: "Sie müssen die AGB akzeptieren um fortzufahren.",
    errorDatePeriodIsInvalid:
      "Das Start Datum muss kleiner sein als das End Datum!",
    errorDatePeriodEmpty: "Die Datum Felder müssen ausgefüllt sein.",
    errorDatePeriodMinValueStart:
      "Das Start Datum muss größer gleich dem heutigen Datum sein!",
    errorDatePeriodMinValueEnd:
      "Das End Datum muss größer gleich dem heutigen Datum sein!",
    errorDurationIsInvalid: "Die Dauer darf nicht negativ sein",
    errorMaxDurationIsInvalid:
      "Die Minimum Dauer muss kleiner sein als die Maximal Dauer",
    errorMinDurationIsInvalid:
      "Die Maximal Dauer muss größer sein als die Minimal Dauer",
    themecartIsNull: "Unbekannter Sammelkorb",
    perPage: "pro Seite",
    userExportModalTitle1: "Sammelkorb",
    userExportModalTitle2:
      "teilen mit (REGISTRIERTE EMPFÄNGER SUCHEN, MEHRFACHAUSWAHL MÖGLICH):",
    userExportModalResults: "Resultate",
    userExportModalSelection: "Empfängerliste",
    userExportModalSubmit: "Teilen",
    userExportModalSuccessMSG:
      "Sammelkorb wurde erfolgreich mit den ausgewählten Usern geteilt.",
    targetExportModalTitle: "Zielauslieferung",
    targetExportModalMsg:
      "Bitte wählen sie aus, wohin sie den Sammelkorb {cart} ausliefern möchten.",
    targetExportMSG:
      "Ihr Auftrag zur Zielauslieferung wird bearbeitet, bitte schließen Sie dieses Fenster erst, wenn alle Tracks ausgeliefert wurden.",
    productionNumber: "Produktions-Nr.",
    target: "Ziel",
    targetExportSuccessMSG:
      "Alle Tracks erfolgreich ausgeliefert! Sie können diese Seite nun schließen!",
    targetExportResult: "Resultat",
    targetExportModalSubmit: "Ausliefern",
    publicLinkModalTitle: "ÖFFENTLICHER LINK",
    publicLinkModalMSG:
      "Dies ist ein öffentlicher Link für Ihren Sammelkorb {cart}, sie können diesen teilen und versenden. Auch unregistrierte Benutzer haben Einblick darauf.",
    copyScsTxt: "In die Zwischenablage kopiert!",
    resetPasswordTitle: "Kennwort zurücksetzen",
    cartEditModalTitle: "Sammelkorb bearbeiten",
    change: "Ändern",
    deleteCartModalMsg: "Wollen Sie wirklich den Sammelkorb {cart} löschen?",
    deleteCartsModalMsg:
      "Wollen Sie wirklich die ausgewählten Sammelkörbe löschen?",
    deleteSongModalTitle: "Track aus Sammelkorb löschen",
    deleteSongsModalTitle: "Tracks aus Sammelkorb löschen",
    deleteSongModalMsg:
      "Wollen Sie wirklich den Track {song} aus den Sammelkorb {cart} entfernen?",
    deleteSongsModalMsg:
      "Wollen Sie wirklich die ausgewählten Tracks aus den Sammelkorb {cart} entfernen?",
    noCartsInSelect: "Keine Sammelkörbe vorhanden",
    deleteModalTitle: "Sammelkorb löschen",
    deleteCartsModalTitle: "Sammelkörbe löschen",
    deleteSongSuccessMSG: "Track erfolgreich aus Sammelkorb entfernt!",
    deleteSongsSuccessMSG: "Tracks erfolgreich aus Sammelkorb entfernt!",
    deleteCartSuccessMSG: "Sammelkorb erfolgreich gelöscht!",
    deleteCartsSuccessMSG: "Sammelkörbe erfolgreich gelöscht!",
    deleteThemecartModalTitle: "Themensammlung löschen",
    deleteThemecartModalMSG:
      "Wollen sie wirklich die Themensammlung {themecart} löschen?",
    deleteThemecartSuccessMSG: "Themensammlung erfolgreich gelöscht!",
    deleteThemecartsSuccessMSG: "Themensammlungen erfolgreich gelöscht!",
    deleteThemecartsModalTitle: "Themensammlungen löschen",
    deleteThemecartsModalMSG:
      "Wollen sie wirklich folgende Themensammlungen löschen?",
    deleteUserModalTitle: "Benutzer löschen",
    deleteUserModalMSG: "Wollen sie wirklich den Benutzer {user} löschen?",
    deleteUserModalSuccessMSG: "Benutzer erfolgreich gelöscht!",
    error_500_title: "Interner Server Fehler",
    error_general_title: "Ups, etwas ist schief gegangen!",
    error_internetExplorerNotSupported:
      "Lieber Benutzer! Ihr verwendeter Browser wird leider nicht mehr unterstützt. Bitte benutzen Sie entweder Firefox, Microsoft Edge oder Google Chrome. Vielen Dank für Ihr Verständnis!",
    error_internetExplorerNotSupported_title:
      "Internet Explorer wird nicht mehr unterstützt",
    // error_mailRecipientUnavailable:
    //   "Wir konnten Ihre neue Email Adresse leider nicht erreichen. Bitte überprüfen Sie Ihre Eingabe!",
    found: "Gefunden",
    error_notFound_title:
      "Leider konnten wir die Seite nicht finden, die Sie gesucht haben.",
    error_notFound_message1: "Was könnte das verursacht haben",
    error_notFound_message2:
      "Vielleicht haben wir eine Seite entfernt, als wir diese Webapplikation neu entwickelt haben.",
    error_notFound_message3:
      "Oder der Link den Sie geklickt haben könnte alt sein und funktioniert nicht mehr.",
    error_notFound_message4:
      "Oder vielleicht haben Sie die falsche URL in ihren Browser eingegeben.",
    error_notImplemented_msg:
      "Lieber Benutzer, die aufgerufene Funktion (oder ein Teil davon) befindet sich noch in Entwicklung. Bitte probieren Sie diese zu einen späteren Zeitpunkt wieder. Danke für Ihre Geduld!",
    error_notImplemented_title: "In Entwicklung",
    error_general_message1:
      "Wir entschuldigen uns für die Unannehmlichkeiten, aber ein unerwarteter Fehler ist aufgetreten während Sie unsere Webseite verwendet haben.",
    error_general_message2: "Was können sie tun?",
    error_general_message3:
      "Sie könnten versuchen die URL noch einmal in den Browser einzugeben.",
    error_general_message4: "Oder wir können Sie zur {anchor}.",
    error_general_message5: "Eine Sache noch:",
    error_general_message6:
      "Wenn sie wollen, können sie uns über veraltete Links {anchor}.",
    error_general_message6a: "informieren",
    error_general_message4a: "Home Page zurückzuleiten.",
    // UI_Authentication_UserLocked:
    //   "Ihr Benutzer wurde gesperrt. Sind Sie der Meinung, dass dies ein Fehler ist wenden Sie sich bitte an musikverlag@orf.at",
    // UI_Authentication_UserEmailUnverified:
    //   "Sie haben Ihre Email-Adresse noch nicht verifiziert.",
    // UI_Authentication_UserUnverified:
    //   "Ihr Benutzer wurde noch nicht freigeschalten.",
    // UI_InputValidation_PasswordPolicyFailure_OldPassword:
    //   "Wir haben unsere Kennwort Richtlinien aktualisiert, ihr altes Kennwort erfüllt diese nicht. Bitte setzen Sie ihr Kennwort zurück.",
    // UI_Authentication_Login_Failed:
    //   "Die Emailadresse oder das Kennwort ist falsch.",
    UI_RegisterForm_Error_UserAlreadyExists:
      "Es existiert bereits ein Account unter dieser Email Adresse.",
    UI_RegisterForm_Error: "Konto konnte nicht angelegt werden.",
    // UI_Authentication_PasswordResetTimeError:
    //   "Sie haben ihr Passwort bereits zurückgesetzt. Bitte überprüfen Sie Ihren Posteingang!",
    UI_Authentication_ResetPassword_LinkExpired:
      "Dieser Link ist abgelaufen. Falls Sie ihr Kennwort zurücksetzen wollen, fordern Sie einen Neuen an.",
    reset_password_success_message:
      "Sollte es einen Benutzer in unserem System mit angegebener Email-Adresse geben, haben Sie jetzt eine Email mit weiteren Anweisungen bekommen.",
    UI_Authentication_ResetPassword_Success:
      "Ihr Kennwort wurde erfolgreich zurückgesetzt.",
    Error_MailRecipientUnavailable:
      "Wir konnten Ihre neue Email Adresse leider nicht erreichen. Bitte überprüfen Sie Ihre Eingabe!",
    change_email_request_success_title: "Email Änderungsanfrage erhalten!",
    change_email_request_success_message:
      "Wir haben so eben eine Verifizierungsmail an Ihre neue Email Adresse gesendet, es kann bis zu 10 Minuten dauern bis Sie diese erhalten. Bitte verifizieren Sie Ihre neue Email Adresse um den Prozess abzuschließen.",
    UI_UserEmailVerification_Success_Title: "Email Verifizierung erfolgreich!",
    UI_UserEmailVerification_Success_AdditionalText:
      "Ihre Email Adresse wurde erfolgreich verifiziert. Sobald Sie vom Musikverlag Team freigeschaltet wurden, können Sie die Applikation uneingeschränkt nutzen.",
    UI_UserEmailChange_Success_Title: "Email Adresse erfolgreich geändert!",
    UI_UserEmailChange_Success_AdditionalText:
      "Die Änderung Ihrer Email Adresse wurde abgeschlossen und verifiziert. Sie können sich nun mit {email} einloggen.",
    UI_UserEmailVerification_Failure_Title:
      "Email Verifizierung fehlgeschlagen!",
    UI_UserEmailVerification_Failure_AdditionalText:
      "Wir konnten diesen Link zur Email-Verifizierung nicht verarbeiten. Bitte informieren Sie den Support unter musikverlag@orf.at.",
    update_account_success_message:
      "Konto Informationen erfolgreich aktualisiert!",
    UI_UserVerification_Failure_Title: "Verifizierung fehlgeschlagen!",
    UI_UserVerification_Failure_AdditionalText:
      "Der Benutzer konnte nicht verifiziert werden. Bitte informieren Sie den Support unter musikverlag@orf.at.",
    UI_UserVerification_Success_Title: "Verifizierung erfolgreich!",
    UI_UserVerification_Success_AdditionalText:
      "Der Benutzer {email} wurde erfolgreich freigeschalten. Eine Benachrichtungsemail wurde bereits versendet. ",
    tooltipSimSearch: "KI-Lösung zum finden von musikalisch ähnlichen Tracks. Die Eingabe eines Referenz-Titels ist per URL (z.B. Youtube-Link), Audio-Upload oder Library-ArchivNr. möglich.",
    tooltipDeleteCartButton: "Sammelkorb löschen",
    tooltipDeleteCartsButton: "Ausgewählte Sammelkörbe löschen",
    tooltipEditCartButton: "Sammelkorb bearbeiten",
    tooltipLoadCartButton: "Sammelkorb laden",
    tooltipTransferCartSongsButton: "Tracks in anderen Sammelkorb verschieben",
    tooltipAddCartButton: "Neuen Sammelkorb anlegen",
    tooltipHideSearch: "Erweiterte Suche verstecken/anzeigen",
    tooltipResetField: "Feld zurücksetzten",
    tooltipPlaySong: "Track abspielen",
    tooltipPlaySongDragDrop:
      "Track abspielen oder Maustaste halten zum verschieben",
    tooltipStopSong: "Track pausieren",
    tooltipStopSongDragDrop:
      "Track pausieren oder Maustaste halten zum verschieben",
    tooltipDownloadMP3Song: "Download als MP3",
    tooltipDownloadWAVSong: "Download als WAV",
    tooltipDownloadMP3Songs: "Download der ausgewählten Tracks als MP3",
    tooltipDownloadWAVSongs: "Download der ausgewählten Tracks als WAV",
    tooltipRemoveSongs: "Ausgewählte Tracks aus Sammelkorb entfernen",
    tooltipAddSongs: "Ausgewählte Tracks dem Sammelkorb hinzufügen",
    tooltipAKMDownload: "AKM Export",
    tooltipCartShare: "Sammelkorb teilen",
    tooltipPublicCart: "Öffentlicher Link",
    tooltipTargetExport: "Zielauslieferung",
    tooltipRemoveSong: "Aus Sammelkorb entfernen",
    tooltipAddSong: "Dem Sammelkorb hinzufügen",
    tooltipMoreInfoSong: "Mehr Informationen anzeigen",
    tooltipShowSimilarTracks: "KI-Lösung zum finden von musikalisch ähnlichen Tracks",
    showSimilarTracksButton: "Ähnlichkeitssuche",
    tooltipLessInfoSong: "Weniger Informationen anzeigen",
    tooltipChangeModi: "Modus ändern",
    tooltipCopyTracks: "Tracks in anderen Sammelkorb kopieren",
    tooltipLoadMore: "Mehr laden",
    tooltipSearchCart: "Nach Sammelkorb suchen",
    tooltipSearchSongInCart: "Nach Track in Sammelkorb suchen",
    tooltipCopyCart: "Sammelkorb kopieren",
    tooltipMoveItemFront: "Track an den Anfang des Sammelkorbs verschieben",
    tooltipMoveItemEnd: "Track an das Ende des Sammelkorbs verschieben",
    tooltipSortOrderAsc: "Tracks Aufsteigend sortieren",
    tooltipSortOrderDesc: "Tracks Absteigend sortieren",
    tooltipAudioPlayerNextSong: "Nächsten Track abspielen",
    tooltipAudioPlayerPrevSong: "Vorherigen Track abspielen",
    tooltipAudioPlayerRepeatSong: "Track wiederholen",
    tooltipAudioPlayerNotRepeatSong: "Track nicht mehr wiederholen",
    tooltipAudioPlayerChangePlaybackSpeed: "Wiedergabegeschwindigkeit ändern",
    tooltipRemoveThemeCart: "Sammelkorb entfernen",
    tooltipShowThemeCart: "Themensammlung veröffentlichen",
    tooltipPrivateThemeCart: "Themensammlung unsichtbar schalten",
    tooltipAddCover: "Neue Coverfile hochladen",
    tooltipEditThemeCart: "Themensammlung bearbeiten",
    tooltipCloneThemeCart: "Themensammlung klonen",
    tooltipCartExcelExport: "Sammelkorb in Excel Datei exportieren",
    tooltipSearchUser: "Nach Benutzer suchen",
    tooltipDeleteUser: "Benutzer löschen",
    adminExcelViewExportSongsTitle:
      "Eine Gruppe von Tracks in Excel Datei exportieren",
    adminExcelViewExportAlbumTitle:
      "Ganze Alben in eine Excel Datei Exportieren ",
    adminExcelViewExportSongsMSG:
      "Um Tracks in eine Excel Datei zu exportieren muss die ArchivNr. angegeben werden. Dabei muss man in das erste Feld die Archiv Nr. des ersten Tracks eintragen, welchen man exportieren will. Mithilfe des Plus kann man mehrere unterschiedliche Bereiche in die selbe Excel Datei exportieren.",
    adminExcelViewExportAllSongsTitle:
      "Alle Tracks in eine Excel Datei exportieren",
    adminExcelViewExportErrorGeneral: "Problem mit der KokoId gefunden.",
    adminExcelViewRemoveKokoIds: "ArchivNr. Bereich zurücksetzten",
    adminExcelViewRemoveAlbumKokoId: "ArchivNr. zurücksetzten",
    adminExcelViewKokoIdError: "Bitte geben sie eine valide ArchivNr. an!",
    adminExcelViewKokoIdRangeError:
      "Die Start ID muss kleiner sein als die End Id!",
    adminExcelViewKokoIdLengthError:
      "Koko IDs müssen die selbe Anzahl an Zahlen besitzen! z.B. CD33/1000/1 - CD33/1001/1 oder CD33/1000 - CD33/1001",
    sortOrderAsc: "Aufsteigend",
    sortOrderDesc: "Absteigend",
    sortOrderTypeDefault: "Standard",
    sortOrderTypeTitle: "Titel",
    sortOrderTypeLength: "Dauer",
    sortOrderTypeLastAdded: "Zuletzt hinzugefügt",
    sortOrderTypeAlbum: "Album",
    sortOrderTypeKokoId: "Koko Id",
    sortOrderTypeCustom: "Benutzerdefiniert",
    sortOrderTypeName: "Name",
    sortOrderTypeCreationDate: "Erstellungsdatum",
    sortOrderLabel: "Sortieren nach",
    dontShowAgainLabel: "Nicht mehr anzeigen",
    okSubmit: "Verstanden",
    themeCartTableHeadTitle: "Titel",
    themeCartTableHeadDesc: "Beschreibung",
    themeCartTableHeadCart: "Sammelkorb",
    themeCartTableHeadLink: "Link",
    themeCartTableHeadOnline: "Sichtbar?",
    themeCartTableCoverfile: "Coverfile",
    themeCartViewAdd: "Neue Themensammlung anlegen",
    noCollections: "Noch keine Themensammlungen hinzugefügt",
    chooseVersion: "Version auswählen",
    newsModalMaintainanceTitle: "Wartungsinformationen UTC+01:00",
    newsModalTitle: "Musiksammlungen und News",
    themeCollectionModalTitle: "Musiksammlungen",
    newsModalSupportMsgLabel: "Neue Features",
    maintainanceItemStartDate: "Anfangsdatum UTC+01:00",
    maintainanceItemEndDate: "Enddatum UTC+01:00",
    maintainanceItemSaveSuccess: "Wartungsperiode erfolgreich gespeichert",
    maintainanceItemResetSuccess: "Wartungsperiode erfolgreich zurückgesetzt",
    maintainanceAdminViewTitle: "Wartungsperioden",
    maintainanceAdminViewMsg:
      "Auf dieser Seite kann man das nächste Wartungsfenster festlegen.",
    maintainanceAdminViewAppSupportMessageTitle: "Feature Nachrichten",
    maintainanceAdminViewAppSupportTitle: "Features Titel",
    appSupportMessageTableOrderHead: "Reihenfolge",
    appSupportMsgCreateTitle: "Nachricht erstellen",
    appSupportMsgCreated: "Nachricht erfolgreich erstellt!",
    appSupportMsgUpdatedSuccess: "Nachricht erfolgreich gespeichert!",
    appSupportMsgDelete: "Nachricht löschen",
    appSupportMsgEdit: "Nachricht bearbeiten",
    appSupportMsgAdd: "Neue Nachricht hinzufügen",
    appSupportTitleAdd: "Neuen Titel hinzufügen",
    appSupportTitleCreateTitle: "Titel erstellen",
    appSupportTitleCreated: "Titel erfolgreich erstellt!",
    appSupportTitleUpdatedSuccess: "Titel erfolgreich gespeichert!",
    appSupportTitleDelete: "Titel löschen",
    appSupportTitleEdit: "Titel bearbeiten",
    appSupportTitleSelectorEmpty: "Titel wählen",
    releasedate: "Releasedatum",
    statisticAdminViewStatType0: "Allgemeine Informationen",
    statisticAdminViewStatType1: "Queries",
    statisticAdminViewStatType2: "Benutzer",
    statisticAdminViewStatType3: "Tracks",
    statisticAdminViewStatType4: "Sammelkörbe",
    statisticAdminViewStatType5: "Dropdowns",
    statisticAdminViewStatType6: "Webseitenaufrufe",
    statisticAdminViewStatType7: "Webseitenaufrufe per Monat",
    statisticAdminViewStatType8: "Neue Benutzer per Monat",
    statisticAdminViewStatType9: "Querie Suchen per Monat",
    statisticAdminViewStatType10: "Dropdown Suchen per Monat",
    statisticAdminViewStatType11: "Anzahl Genres",
    statisticAdminViewStatType12: "Anzahl Keywords",
    statisticAdminViewDisplayType0: "Tabelle",
    statisticAdminViewDisplayType1: "Tortendiagramm (Anzahl der Aufrufe)",
    statisticAdminViewDisplayType2: "Säulendiagramm  (Anzahl der Aufrufe)",
    statisticAdminViewDisplayType3: "Liniendiagramm (Anzahl der Aufrufe)",
    statisticAdminViewDisplayType1Songs: "Tortendiagramm (Anzahl der Plays)",
    statisticAdminViewDisplayType2Songs: "Säulendiagramm  (Anzahl der Plays)",
    statisticAdminViewDisplayType3Songs:
      "Tortendiagramm (Anzahl der Downloads)",
    statisticAdminViewDisplayType4Songs:
      "Säulendiagramm  (Anzahl der Downloads)",
    statisticAdminViewTableHeadNoResQuery: "Suchwort",
    statisticAdminViewTableHeadNoResResults: "Resultate",
    statisticAdminViewTableHeadNoResEmotion: "Emotion/Stimmung",
    statisticAdminViewTableHeadNoResMusicOccasion: "Musik Für",
    statisticAdminViewTableHeadNoResInstrument: "Instrument",
    statisticAdminViewTableHeadNoResMusicStyle: "Genre",
    statisticAdminViewTableHeadNoResMinDuration: "Min. Dauer in Sec.",
    statisticAdminViewTableHeadNoResMaxDuration: "Max. Dauer in Sec.",
    statisticAdminViewTableHeadNoResTimesCalled: "Aufgerufen",
    statisticAdminViewTableHeadNoResDate: "Datum",
    statisticsAdminViewTableNoResultsToShow: "Keine Daten zum Anzeigen",
    statisticAdminViewTableHeadRegDate: "Registrierungsdatum",
    statisticAdminViewTableHeadTitle: "Titel",
    statisticAdminViewTableHeadFirstName: "Name",
    statisticAdminViewTableHeadLastName: "Nachname",
    statisticAdminViewTableHeadProfession: "Tätigkeit",
    statisticAdminViewTableHeadCompany: "Unternehmen",
    statisticAdminViewTableHeadEmail: "Email",
    statisticAdminViewTableHeadPhoneNumber: "Tel. Nummer",
    statisticAdminViewTableHeadAccState: "Status",
    statisticAdminViewTableHeadNoCarts: "Anzahl der Sammelkörbe",
    statisticAdminViewTableHeadName: "Name",
    statisticAdminViewTableHeadTotalNumber: "Anzahl",
    statisticAdminViewTableHeadSongCount: "Anzahl der Tracks",
    statisticAdminViewTableHeadTimesCalled: "Anzahl der Aufrufe",
    statisticAdminViewTableHeadGuid: "Link",
    statisticAdminViewTableHeadKokoId: "ArchivNr.",
    statisticAdminViewTableHeadAlbum: "Album",
    statisticAdminViewTableHeadDuration: "Dauer",
    statisticAdminViewTableHeadRecordingYear: "Aufnahmejahr",
    statisticAdminViewTableHeadPlays: "Plays",
    statisticAdminViewTableHeadDownloads: "Downloads",
    statisticAdminViewTableHeadArtists: "Künstler",
    statisticAdminViewGeneralTotalSongs: "Insgesamte Tracks",
    statisticAdminViewGeneralTotalArtists: "Insgesamte Künstler",
    statisticAdminViewGeneralTotalUsers: "Insgesamte Registrierungen",
    statisticAdminViewGeneralTotalCarts: "Insgesamte Sammelkörbe",
    statisticAdminViewGeneralAvgCarts:
      "Durchschnittliche Sammelkörbe pro Benutzer",
    statisticAdminViewGeneralAvgSongs:
      "Durchschnittliche Tracks pro Sammelkorb",
    statisticAdminViewGeneralNoCarts: "Benutzer ohne Sammelkörbe",
    statisticAdminViewExportStats: "Statistik exportieren",
    date: "Datum",
    numberOfAllViewsOverDateRange:
      "Anzahl der kompletten Aufrufe über den Zeitraum",
    entries: "Einträge",
    users: "Benutzer",
    block: "Sperren",
    unblock: "Entsperren",
    verify: "Verifizieren",
    usersAdminViewTableHeadUserId: "ID",
    usersAdminViewTableHeadRegisterDate: "Registrierungsdatum",
    usersAdminViewTableHeadTitle: "Titel",
    usersAdminViewTableHeadName: "Name",
    usersAdminViewTableHeadSurName: "Nachname",
    usersAdminViewTableHeadCompany: "Unternehmen",
    usersAdminViewTableHeadEmail: "Email",
    usersAdminViewTableHeadNumber: "Telefonnummer",
    usersAdminViewTableHeadAccState: "Status",
    usersAdminViewTableHeadButtonAction: "Aktionen",
    dropdownAdminViewCreateNewItem: "Neues Item hinzufügen",
    dropdownAdminViewLanguageSelector: "Sprache",
    dropdownAdminViewLanguageGerman: "Deutsch",
    dropdownAdminViewTableHeadId: "ID",
    dropdownAdminViewTableHeadResKey: "Key",
    dropdownAdminViewTableHeadLucenePattern: "Lucene Muster",
    dropdownAdminViewTableHeadCategory: "Kategorie",
    dropdownAdminViewTableHeadLocalization: "Übersetzung",
    dropdownAdminViewTableHeadButtons: "Funktionen",
    createDropdownItemModalTitle: "Neues Dropdownitem erstellen",
    editDropdownItemModalTitle: "Dropdownitem bearbeiten",
    createDropdownItemModalKey: "Einzigartiger Name",
    createDropdownItemModalLucenePattern: "Lucene Muster",
    createDropdownItemModalLuceneCategory: "Kategorie",
    createDropdownItemModalLuceneLocalization: "Übersetzung",
    createDropdownItemModalDate: "Releasedatum",
    createDropdownItemModalErrorLucenePattern:
      "Das Lucene Pattern führt zu folgenden Fehler: ",
    createDropdownItemModalSuccessMsg: "Dropdown Item erfolgreich erstellt!",
    editDropdownItemModalSuccessMsg: "Dropdown Item erfolgreich gespeichert!",
    deleteDropdownItemModalSuccessMSG: "Dropdown Item erfolgreich gelöscht!",
    deleteDropdownItemModalTitle: "Dropdown Item löschen",
    deleteDropdownItemModalMsg:
      "Wollen sie wirklich das Dropdown Item {item} löschen?",
    deleteAppSupportMsgModalTitle: "Newspopup Nachricht löschen",
    deleteAppSupportSuccessMSG: "Nachricht erfolgreich gelöscht!",
    deleteAppSupportMsgModalMSG:
      "Wollen sie wirklich die Newspopup Nachricht {news} löschen?",
    deleteAppSupportTitleModalTitle: "Newspopup Titel löschen",
    deleteAppSupportTitleSuccessMSG: "Titel erfolgreich gelöscht!",
    deleteAppSupportTitleModalMSG:
      "Wollen sie wirklich den Newspopup Titel {news} löschen?",
    similaritySearchKokoIdRequiredError: "ArchivNr. bitte eintragen",
    similaritySearchKokoIdInvalidError: "ArchivNr. ist invalide",
    similaritySearchUrlRequiredError: "URL bitte eintragen",
    similaritySearchUrlInvalidError:"Derzeit werden nur Soundcloud und Youtube links unterstützt.",
    similaritySearchFileRequiredError: "Bitte Datei auswählen.",
    similaritySearchUrlKokoIdInvalid: "ArchivNr. oder URL ist ungültig. Derzeit werden nur Soundcloud und Youtube-Links unterstützt.",
    similaritySearchFileMp3RequiredError: "Es werden derzeit nur MP3 und WAV Dateien unterstützt.",
    similaritySearchFileSizeError: "Datei zu groß, derzeit werden nur Audio-Dateien bis 140MB unterstützt.",
    similaritySearchNoResults: "Keine ähnlichen Songs gefunden"

  },
};
// TODO: Add random greeting
const i18n = new VueI18n({
  locale: "de", // set locale
  fallbackLocale: "de", // set fallback locale
  messages, // set locale messages,
  silentTranslationWarn: true,
});

export default i18n;
